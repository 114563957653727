:root {
  --brand-color: #0ea5e9;
  --dark-color: #0f172a;
  --mid-color: cbd5e1;
  --light-color: #ffffff;
}

body {
  font-family: sans-serif;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  background: var(--dark-color);
  color: var(--light-color);
}

/* Header */
.header {
  color: var(--brand-color);
}

/* App */
.App {
  max-width: 80vw;
  margin: 10vh auto;
}

/* Button */
button {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
button:hover {
  color: var(--dark-color);
  background-color: var(--brand-color);
}

/* Turns */
.turns {
  color: orange;
  font-weight: bolder;
  font-size: 1.5em;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

img {
  width: 100%;
  aspect-ratio: 1/1;
}

.card {
  position: relative;
}

.card:hover {
  box-shadow: 0px 0px 9px 1px #adb5c5;
}

/* front of cards */

.card img {
  display: block;
  border: 2px solid black;
  border-radius: 6px;
  cursor: pointer;
  object-fit: cover;
}

.card .front {
  transform: rotateY(90deg);
  position: absolute;
  transition: all ease-in 0.2s;
}

.flipped .front {
  transform: rotateY(0deg);
  transition-delay: 0.2s;
}

/* back of cards */

.card .back {
  transition: all ease-in 0.2s;
  transition-delay: 0.2;
}

.flipped .back {
  transform: rotateY(90deg);
  transition-delay: 0s;
}

.result {
  margin-bottom: 30px;
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .card-grid {
    display: inline-grid;
    grid-template-columns: repeat(4, 16vw);
    gap: 20px;
  }
  
}

@media only screen and (min-width: 1001px) and (max-width: 2500px) {
  .card-grid {
    display: inline-grid;
    grid-template-columns: repeat(4, 10vw);
    gap: 20px;
  }
}